import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

Metadata.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    metadescription: PropTypes.string,
    metakeywords: PropTypes.string,
  }).isRequired,
  opengraph: PropTypes.shape({
    title: PropTypes.string,
    type: PropTypes.string,
    image: PropTypes.shape(),
  }),
  defaultTitle: PropTypes.string,
  canonicalSiteUrl: PropTypes.string.isRequired,
  path: PropTypes.string,
};

Metadata.defaultProps = {
  opengraph: null,
  defaultTitle: null,
  path: null,
};

function Metadata(props) {
  const { data, opengraph, defaultTitle, canonicalSiteUrl, path } = props;
  const { title, metadescription, metakeywords } = data || {};
  /* eslint-disable object-curly-newline */
  const { title: ogTitle, description: ogDescription, type: ogType, image: ogImage } = opengraph || {};
  /* eslint-enable object-curly-newline */

  const url = canonicalSiteUrl + path;
  const showOpenGraphTags = ogType || ogTitle || ogImage;

  return (
    <Helmet>
      {(title || defaultTitle) && <title>{title || defaultTitle}</title>}
      {metadescription && <meta name="description" content={metadescription} />}
      {metakeywords && <meta name="keywords" content={metakeywords} />}
      {(ogTitle || title || defaultTitle) && <meta property="og:title" content={ogTitle || title || defaultTitle} />}
      {(ogDescription || metadescription) && (
        <meta property="og:description" content={ogDescription || metadescription} />
      )}
      {ogImage &&
        ogImage.processed &&
        ogImage.processed.fixed && [
          <meta key="og_image_url" property="og:image:url" content={canonicalSiteUrl + ogImage.processed.fixed.src} />,
          <meta key="og_image_height" property="og:image:height" content={ogImage.processed.fixed.height} />,
          <meta key="og_image_width" property="og:image:width" content={ogImage.processed.fixed.width} />,
        ]}
      {showOpenGraphTags && [
        <meta key="og_type" property="og:type" content={ogType || 'website'} />,
        <meta key="og_url" property="og:url" content={url} />,
      ]}
    </Helmet>
  );
}

export default Metadata;
